.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table{
  margin-left: auto;
  margin-right: auto;
}
.summaryExpensesTable{
  
}

.expensetable{
 
}
.expensetable tr:nth-child(even) {background-color: #f2f2f2;}

.expensetable .bag{
  width: 100px;
}

.expensetable .categoryName{
  width: 100px;
}

.expensetable .name{
  width: 400px;
}

.expensetable .date{
  width: 100px;
}

.expensetable .amount{
  width: 10px;
}

.expensetable .price{
  width: 50px;
}

.expensetable .value{
  width: 50px;
}

.expensetable .additions{
  width: 50px;
}

.expensetable .deductions{
  width: 50px;
}

.expensetable .cost{
  width: 100px;
}

.expenseTableBagLabel{
  font-size: 25px;
}

.expenseTableBagValue{
  font-size: 25px;
  font-weight: bold;
}

.expenseTableCategoryLabel{
  font-size: 25px;
}

.expenseTableCategoryValue{
  font-size: 25px;
  font-weight: bold;
}

.linkButton{
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}